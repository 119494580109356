import nanoid from 'nanoid';
import { GraphQLError } from 'graphql';
import { ApolloClient } from 'apollo-client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { ErrorResponse, onError } from 'apollo-link-error';
// todo - remove after update gql
import { from, split } from 'apollo-link';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';

import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { any, equals, filter, pathOr, pipe } from 'ramda';
import { getClid } from 'cf-common/src/analytics';
import { LS } from 'cf-common/src/localStorage';
import {
  requestsEventEmitter,
  customFetch_requestStartOrEndEvent,
} from '@utils/requestsEventEmitter';
import { getPluginTypeByGqlTypename } from '@utils/GQL/utils';
import { IS_DEBUG } from 'cf-common/src/environment';
import { handleUnauthDuringSession } from '@utils/handleUnauth';
import { getCurrentDomain } from '@utils/UrlUtils';
import introspectionQueryResultData from '../../../@types/gqlFragmentTypes.json';
import { resolvers } from './ApolloService.resolvers';
import * as AngularApolloBindings from './ApolloService.angular';
import { directFetch } from '@utils/Fetch/directFetch';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const GQL_IGNORE_UNAUTH_PATHS = new Set(['me', 'pages']); // todo remove *pages* after backend fix

const AUTH_ERROR_CODE = 401;

const hasAuthError = pipe(
  pathOr([], ['response', 'errors']),
  filter<GraphQLError>(
    (error) => !GQL_IGNORE_UNAUTH_PATHS.has((error.path || []).join('/')),
  ),
  any(
    pipe(
      pathOr(200, ['extensions', 'response', 'status']),
      equals(AUTH_ERROR_CODE),
    ),
  ),
);

const hasSubscriptionAuthError = (response: ErrorResponse) =>
  response?.networkError?.message.startsWith(AUTH_ERROR_CODE.toString());

const enrichErrorsLink = onError((response) => {
  if (
    (hasAuthError(response) || hasSubscriptionAuthError(response)) &&
    // we don't want to handle unauthorized errors at chatfuel.com (we expect user to be unauthorized there)
    getCurrentDomain() !== 'chatfuel.com'
  ) {
    handleUnauthDuringSession();
  }

  const { graphQLErrors, operation } = response;
  const { headers } = operation.getContext();

  graphQLErrors?.forEach(({ extensions }) => {
    // eslint-disable-next-line no-param-reassign
    if (extensions) extensions.requestId = headers['x-request-id'];
  });
});

const getToken = () => LS.getRaw('token');

export const getFetchHeaders = (): Record<string, any> => ({
  clid: getClid(),
  'x-request-id': nanoid(),
  Authorization: `Bearer ${getToken()}`,
  configBuildDate: window.CHATFUEL_CONFIG.CONFIG_BUILD_DATE,
  debug: IS_DEBUG ? 'on' : 'off',
});

const withTokenAndClid = setContext(() => {
  return {
    headers: getFetchHeaders(),
  };
});

const customFetch: WindowOrWorkerGlobalScope['fetch'] = (uri, options) => {
  // TODO: remove me after remove angular (this detect update GQL data and fire ng-digest)
  AngularApolloBindings.angularDigestFire();
  requestsEventEmitter.emit(customFetch_requestStartOrEndEvent);
  let opName = '';
  try {
    opName = JSON.parse(options!.body as string).operationName;
  } catch {
    opName = 'Corrupted';
  }

  return directFetch(`${uri}?opName=${opName}`, options).then(
    (response) => {
      AngularApolloBindings.angularDigestFire(); // TODO: remove me after remove angular
      requestsEventEmitter.emit(customFetch_requestStartOrEndEvent);
      return response;
    },
    (reason) => {
      requestsEventEmitter.emit(customFetch_requestStartOrEndEvent);
      throw reason;
    },
  );
};

const cache = new InMemoryCache({
  fragmentMatcher,
  /* eslint-disable no-underscore-dangle */
  dataIdFromObject: (object) => {
    /**
     * Ишуя: https://chatfuel.atlassian.net/browse/CHAT-10289
     *
     * `id` в типе `WhatsappMediaObject` - опциональное поле и может быть нуллом.
     * Когда юзер в плагине whatsapp_template добавляет любой медиа объект
     * (например картинку), то из-за отсутствия на фронте значения для id, в кеше
     * будет лежать значение с ключом `WhatsappMediaObject:null`, если создать
     * второй плагин whatsapp_template и так же в него добавить любой медиа объект,
     * то по той же причине в кеш запишется `WhatsappMediaObject:null`, в следствии
     * чего, значение плагина перетрется.
     *
     * При создании медиа объекта подставлять `id` со значением `link` нельзя, тк
     * этот `id` отправляется в востап и он будет ругаться
     */
    if (object.__typename === 'WhatsappMediaObject') {
      return defaultDataIdFromObject({
        ...object,
        // @ts-expect-error
        id: object.id || object.link,
      });
    }
    AngularApolloBindings.angularDigestFire(); // TODO: remove me after remove angular (this detect update GQL data and fire ng-digest)

    // use clear id for all Plugins
    if (object.__typename && getPluginTypeByGqlTypename(object.__typename)) {
      return object.id;
    }

    if (object.__typename === 'ContactCustomAttr') {
      // name is required in ContactCustomAttr
      return `${object.__typename}:${(object as any).name}`;
    }

    switch (object.__typename) {
      // больше так не делаем!!!
      case 'AiGroup':
      case 'AiIntent':
      case 'Block':
      case 'AIPlugin':
      case 'BlocksGroup':
      case 'Admin':
      case 'Page':
        return object.id;
      default:
        return defaultDataIdFromObject(object);
    }
  },
  /* eslint-enable no-underscore-dangle */
  cacheRedirects: {
    Page: {
      currentUserBot: ({ bot_id }, _, { getCacheKey }) =>
        getCacheKey({ id: bot_id, __typename: 'Bot' }),
    },
    Query: {
      card: (_, { id }, { getCacheKey }) =>
        getCacheKey({ id, __typename: 'DefaultCommonTypePlugin' }),
      page: (_, { pageId: id }, { getCacheKey }) => {
        return getCacheKey({ id, __typename: 'Page' });
      },
      flowBlock: (_, { flowId: id }, { getCacheKey }) => {
        return getCacheKey({ id, __typename: 'FlowBlock' });
      },
      livechatConversation: (
        _,
        { conversationId: id, botId, platform },
        { getCacheKey },
      ) => {
        return getCacheKey({
          id,
          botId,
          platform,
          __typename: 'LivechatConversationV3',
        });
      },
      workspace: (_, { workspaceId: id }, { getCacheKey }) => {
        return getCacheKey({ id, __typename: 'Workspace' });
      },
    },
    Bot: {
      facebookAdDetailed: (_, { id }, { getCacheKey }) =>
        getCacheKey({ id, __typename: 'FacebookAd' }),
      flow: (_, { id }, { getCacheKey }) =>
        getCacheKey({ id, __typename: 'Flow' }),
      conversation: (_, { id }, { getCacheKey }) =>
        getCacheKey({ id, __typename: 'LivechatConversationV3' }),
    },
  },
});

const DEFAULT_TIMEOUT = 2 * 60 * 1000; // 2 min

const timeoutLink = new ApolloLinkTimeout(DEFAULT_TIMEOUT);

const httpLink = new HttpLink({
  fetch: customFetch,
  uri: '/graphql',
});

class LazyTokenInjectWebSocket extends WebSocket {
  constructor(url: string | URL, protocols?: string | string[]) {
    super(`${url}?token=${getToken()}`, protocols);
  }
}

const wsLink = new WebSocketLink({
  uri: `wss://${window.location.host}/subscription-graphql`,
  options: {
    reconnect: true,
    reconnectionAttempts: process.env.NODE_ENV === 'development' ? Infinity : 5,
    lazy: true,
    connectionParams: getFetchHeaders,
  },
  webSocketImpl: LazyTokenInjectWebSocket,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const clientOptions = {
  resolvers,
  cache,
  link: from([withTokenAndClid, enrichErrorsLink, timeoutLink, link]),
  connectToDevTools: true,
};

const client = new ApolloClient(clientOptions);

export default client;
